// extracted by mini-css-extract-plugin
export var banner = "ImageText-module--banner--53a39";
export var container = "ImageText-module--container--1c64a";
export var description = "ImageText-module--description--c026d";
export var imageHolder = "ImageText-module--imageHolder--7eb03";
export var italic = "ImageText-module--italic--23d32";
export var main = "ImageText-module--main--796bc";
export var previewBtn = "ImageText-module--previewBtn--4de7b";
export var rightContainer = "ImageText-module--rightContainer--12acd";
export var text = "ImageText-module--text--2aeeb";
export var title = "ImageText-module--title--41d67";