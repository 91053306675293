import React from 'react';
import * as styles from "./ProductsFooter.module.scss";
import Library from "../../../images/libraryIcon.svg"
import Studio from "../../../images/studioIconn.svg"
import WiZRX from "../../../images/wizrxIcon.svg"
import Connect from "../../../images/connectIcon.svg"
import WizrUp from "../../../images/wizrupIcon.svg"
import Magazine from "../../../images/magazineIcon.svg"
import MPublisher from "../../../images/mpublisherIcon.svg"
import Dashboard from "../../../images/videodashboardIcon.svg"
import Space from "../../../images/wizrspaceIcon.svg"
import { navigate } from 'gatsby';

export default function ProductsFooter() {
    const icons = [
        {icon: <Library />, toLink: "library"},
        {icon: <Studio />, toLink: "studio"},
        {icon: <WiZRX />, toLink: "wizr-x"},
        {icon: <Connect />, toLink: "wizr-connect"},
        {icon: <WizrUp />, toLink: "wizr-up"},
        {icon: <Magazine />, toLink: "magazine"},
        {icon: <MPublisher />, toLink: "mpublisher"},
        {icon: <Dashboard />},
        {icon: <Space />}
    ]
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {icons.map((d, i) => {
            return (
                <div onClick={() => navigate(`/${d?.toLink}`)} key={i} className={styles.iconContainer}>
                {d.icon}
                </div>
            )
        })}
      </div>
    </div>
  )
}
