import React from 'react';
import * as styles from "./FeedbackSection.module.scss";

export default function FeedbackSection() {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
    <div className={styles.text}>
    <h1 className={styles.title}>Inspiring <span>colorful</span> dialogue</h1>
    <p className={styles.description}>through visual <span>storytelling</span> and <br/> innovative ways to <span>connect</span></p>
    </div>
    <img  src='https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710025448/Templates/Frame_1261153081_1_vprwmm.png' alt="feedback"/>
      </div>
    </div>
  )
}
