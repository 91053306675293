import React from 'react';
import * as styles from "./ImageText.module.scss";

export default function ImageText({ image, title, description, right, subtitle, oneline, toLink, extraClass }) {
    return (
        <div className={styles.main}>

            <div className={`${styles.container} ${right ? styles.rightContainer : ""} ${styles[extraClass]}`}>
                <div className={styles.imageHolder}>
                    <img src={image} alt='default' />
                    <button className={styles.previewBtn} onClick={() => window.open(`${toLink}`, "_blank")}>See More</button>
                </div>
                <div className={styles.text}>
                    <h3 className={styles.title}>{title}<br /><span> {subtitle}</span></h3>
                    <p className={styles.description}>{description}</p>
                    <i className={styles.italic}>{oneline}</i>
                </div>
            </div>
        </div>
    )
}
