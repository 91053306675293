import React, { useState } from "react";
import { navigate } from "gatsby";
import { showrooms } from "../constants/home";
import ImageText from "../components/molecules/ImageText/ImageText";
import FeedbackSection from "../components/molecules/FeedbackSection/FeedbackSection";
import * as styles from "./styles.module.scss";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import Header from "../components/atoms/header";
import ProductsFooter from "../components/molecules/ProductsFooter/ProductsFooter";
import FullscreenGeneral from "../components/atoms/Fullscreen/FullscreenGeneral";
import { Helmet } from "react-helmet";

export default function Mw() {
  // const [password, setPassword] = useState('');
  // const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  // const [isAuthorized, setIsAuthorized] = useState(false);
  // const checkPassword = () => {
  //   event.preventDefault();
  //   if (password === 'Masterw!zr') {
  //     setIsAuthorized(true);
  //   } else {
  //     navigate('/');
  //   }
  // };

  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     checkPassword();
  //   }
  // };

  // if (!isAuthorized) {
  //   return (
  //     <div className={styles.passwordOverlay}>
  //       <div className={styles.passwordPrompt}>
  //         <input
  //           type={isPasswordVisible ? 'text' : 'password'}
  //           value={password}
  //           onChange={(e) => setPassword(e.target.value)}
  //           onKeyPress={handleKeyPress}
  //           placeholder="Enter password"
  //         />
  //         <button className={styles.showhidepsw} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
  //           {isPasswordVisible ? <AiFillEye/> : <AiFillEyeInvisible />}
  //         </button>
  //         <button className={styles.submitPassword} onClick={checkPassword}>Submit</button>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      <Helmet>
        <title>MW</title>
        <link rel="canonical" href="https://masterwizr.com/mw" />
      </Helmet>
      <FullscreenGeneral />
      <Header />
      <div className={styles.hiddenContainer}>
        <div className={styles.bannerHidden}>
          {showrooms.slice(0, 1).map((d, i) => {
            return <ImageText image={d?.image} key={i} {...d} />;
          })}
        </div>
        <div className={styles.showroomsContainer}>
          {showrooms.slice(1, 6).map((d, i) => {
            return <ImageText image={d?.image} key={i} {...d} />;
          })}
        </div>
        <FeedbackSection />
        <ProductsFooter />
      </div>
    </>
  );
}
